.site_header {
    padding:2rem 0;
    h1 {
        font-family: 'Roboto Mono', monospace;
        font-size: 1.5rem;
        margin-bottom: 0;
    }
    ul {
        a {
            color: #000;
            font-size: 1.2rem;
            &:hover {
                color: rgb(209, 139, 0);
            }
        }
    }    
}
