.jumbotron {
    text-align: left;
    padding: 2rem 0;
    h2 {
        font-family: 'Roboto Mono', monospace;
        line-height: 4rem;
        font-size: 2rem;
        font-weight: bolder;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 65%, rgb(255, 218, 130) 65%);
        display: inline;
    }
}

@media (min-width:768px) {
    .jumbotron {
        h2 {
            line-height: 5rem;
            font-size: 3rem;
        }
    }
}