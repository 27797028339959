.experience_section {
    padding: 1rem; 
    font-family: 'Roboto Mono', monospace;
}

.experience_list {
    list-style: none;
    margin: 0;
    padding: 0;

    span {
        display: block;
        padding: 1rem 0 .25rem 0;
    }

    ul {
        list-style: none;
        margin: 0; 
        padding: 0;
        li {
            display: inline-block;
            padding: .25rem .5rem;
            margin: .25rem;
            background: rgb(255, 218, 130) 65%;
            border: 1px solid #000;
            border-radius: 5px;
        }
    }
}